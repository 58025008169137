.episode {
  overflow-y: scroll;
  height: 100%;
  padding: 30px 30px;
  background-color: #eee;
}

table {
  background-color: #fff;
}
.pro form #select {
  margin: 10px 0px;
}
.pro form label {
  margin: 10px 0px 0px 0px;
  padding: 0;
}
.pro form .fa {
  background-color: #fff;
  padding: 10px;
}

.episodename {
  display: block;
  height: auto;
  border-radius: 4px;
  padding: 0px 10px;
  margin: 0px;
}
.height {
  border: 1px solid #ccc;
  height: 300px;
}
