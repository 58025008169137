.list_cat {
  display: inline-block;
  height: 80vh;
  width: 100%;
  margin: 0px 0%;
  overflow-y: scroll;
}
.list_cat label {
  color: #fff;
  font-size: 16px;
  border-bottom: 2px solid #ccc;
  background-color: orange;
  border-radius: 12px;
  padding: 0px 14px;
  margin: 10px 0px 0px 0px;
}
.fa-trash:hover,
.fa-edit:hover,
.fa-ellipsis-v:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
